import { Magic } from "magic-sdk";

const MAGIC_API_KEY = import.meta.env.VITE_MAGIC_API_KEY;
const RPC_URL = import.meta.env.VITE_RPC_URL;
const NETWORK_ID = import.meta.env.VITE_NETWORK_ID;

const customNodeOptions = {
  rpcUrl: RPC_URL,
  chainId: NETWORK_ID,
};

export const magic = new Magic(MAGIC_API_KEY, {
  network: customNodeOptions
});

// Starts downloading the static assets required to render the Magic iframe context.
magic.preload;

export default magic;
